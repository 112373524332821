export default{
    // login:{
    //     method:'post',
    //     url:'user/login'
    // },
    // recupera:{
    //     method:'post',
    //     url:'login/esquecir/senha'
    // },
    // session:{
    //     method:'get',
    //     url:'user'
    // },
    // logout:{
    //     method:'get',
    //     url:'user/logout'
    // },
    // contrato:{
    //     method:'post',
    //     url:'contrata/mobe'
    // },
    cadastra:{
        method:'post',
        url:'home/cadastro'
    },
    editar:{
        method:'get',
        url:'home/edit{/id}'
    },
    update:{
        method:'post',
        url:'home/update'
    }
}