export default[
    {
        path: '/home',
        name: 'home', 
        component: () => import(/* webpackChunkName: "Login" */ './page/home/cadastro.vue')
    },
    // {
    //     path: '/home/cadastro',
    //     name: 'homecadastro', 
    //     component: () => import(/* webpackChunkName: "Login" */ './page/home/cadastro.vue')
    // },
    {
        path: '/home/editar',
        name: 'homeeditar', 
        component: () => import(/* webpackChunkName: "Login" */ './page/home/editar.vue')
    },
    {
        path: '/fornecedor',
        name: 'fornecedor', 
        component: () => import(/* webpackChunkName: "Login" */ './page/fornecedor.vue')
    },
    {
        path: '/mercadoria',
        name: 'mercadoria', 
        component: () => import(/* webpackChunkName: "Login" */ './page/mercadoria.vue')
    },
    {
        path: '/motorista',
        name: 'motorista', 
        component: () => import(/* webpackChunkName: "Login" */ './page/motorista.vue')
    },
]