export default{
    login:{
        method:'post',
        url:'user/login'
    },
    cadastra:{
        method:'post',
        url:'user/cadastra'
    },
    recupera:{
        method:'post',
        url:'login/esqueci/senha'
    },
    session:{
        method:'get',
        url:'user'
    },
    logout:{
        method:'get',
        url:'user/logout'
    },
    contrato:{
        method:'post',
        url:'contrata/mobe'
    }
}